<template>
  <div class="trailer">
    <div class="trailer-carousel" v-if="isTrailerPresent">
      <div class="slider-header">
        <div class="slider-head" style="padding-bottom: 4rem;">
          <div class="slider-head-btns">
            <div :id="`trailer-controls`" class="slider-controls">
              <!--previous-arrow-->
              <button type="button" class="prev">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
              </button>
              <!--next-arrow -->
              <button type="button" class="forward">
                <img src="@/assets/icons/more-than-filled-50.png" width="30px" alt="more" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="trailer-carousel" class="slider-content">
        <div v-for="(item, index) in trailerContents" :key="index">
          <categoryCard :content="content" :cardType="fetchCardType" @play="playTrailer"></categoryCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { eventBus } from "@/eventBus";
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { mapGetters } from 'vuex';

export default {
  props: {
    content: {
      type: Object,
    },
    cardType: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      slider: null,
      trailerContents: [],
      isTrailerPresent: false,
    };
  },
  computed: {

    ...mapGetters(["appConfig"]),

    poster() {
      return this.getPoster();
    },
    fetchCardType() {
      return {
        cardName: "Trailer",
        type: "LANDSCAPE",
        height: "90px",
        width: "148px",
        playButton: { enablePlay: true, height: "40px", width: "40px" },
      };
    },
  },
  created() {
    console.log(" Trailer Content", this.content);
    this.constructCarousel();
  },
  methods: {
    playTrailer() {
      //Set the videotype to trailer.
      this.content.videoType = "Trailer";
      //To aviod drm check.
      this.content.isDrmContent = false;
      //enable player component.
      eventBus.$emit("player-visibility", true);
      //create a content payload and load the player with the content.
      let payload = {
        content: this.content,
      };
      eventBus.$emit("load-player-content", payload);
      //assigning trailer url as stream url.
      this.content.streamUrl = this.content.trailer[0].filelist[0].filename;
      //Creating playlist item and invoking loadContent method.
      this.createPlayerInstance(this.content);
    },
    createPlayerPlaylistItem(content) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        source: content.streamUrl != undefined && content.streamUrl != "" ? [content.streamUrl] : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        // thumbnail: this.getPoster(content),
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: "noorplay",
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: 0,
      });

      return playlistItem;
    },
    createPlayerInstance(content) {
      let playlist = [];

      let playlistItem = this.createPlayerPlaylistItem(content);

      playlist.push(playlistItem);

      this.playerInstance
        .loadContent(playlist)
        .then(() => {
          this.playerInstance
            .play()
            .then(() => {
              console.log("Playback successful in app");
            })
            .catch((error) => {
              console.log("Error name : ", error.name);
              console.log("Error message : ", error.message);
            });
        })
        .catch((error) => {
          console.log("Playback error in app : ", error.name);
          console.log("Playback error in app : ", error.message);
        });
    },
    constructCarousel() {
      if (!this.content) return;

      if (this.content.trailer && this.content.trailer.length > 0) {
        this.isTrailerPresent = true;
        this.content.trailer.forEach((element) => {
          this.trailerContents.push(element);
        });
      } else {
        this.isTrailerPresent = false;
        return;
      }

      let data = {
        container: `#trailer-carousel`,
        controlsContainer: `#trailer-controls`,
        nav: false,
        loop: true,
        slideBy: "page",
        gutter: 5,
        responsive: {
          576: {
            edgePadding: 5,
            gutter: 5,
            items: 1,
          },
          700: {
            items: 2,
            edgePadding: 5,
            gutter: 5,
          },
          900: {
            edgePadding: 5,
            gutter: 15,
            items: 3,
          },
          1900: {
            edgePadding: 5,
            gutter: 5,
            items: 4,
          },
        },
      };
      this.$nextTick(() => {
        this.slider = tns(data);
      });
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/tiny-slider/src/tiny-slider.scss";
.trailer {
  position: absolute;
  // bottom: 10%;
  width: 100%;
  .trailer-carousel {
    position: relative;
    .slider-head {
      display: grid;
      justify-content: end;
      .slider-head-btns {
        .slider-controls {
          .prev {
            display: none;
          }
          .forward {
            position: absolute;
            top: 38%;
            right: -8%;
          }
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            img {
              height: 40px;
              width: 40px;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .slider-content {
      padding: 10px;
      .card {
        height: 90px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: center;
        opacity: 0.6;
        transition: all 0.4s ease-in-out;
        &:hover {
          opacity: 1;
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>
